import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FlatList } from "react-native";
import { Product } from "../Product";
import { useCart } from "../../hooks/use-cart";
import useProducts from "../../store/products/useProducts";

import { Product as ProductService, Category as CategoryService, Store as StoreService } from '@inhouse-market/sdk';
import { useNavigation } from "@react-navigation/native";
import { useStore } from "../../hooks/use-store";
import { BaseClass } from "../BaseClass";

interface ProductsClassProps {
	category: CategoryService.CategoryItem
	store: StoreService.Store
}

export const ProductsClass: React.FC<ProductsClassProps> = ({
	category,
	store,
}) => {
	const navigation = useNavigation()
	const cart = useCart();
	const paramStore = useStore();
	const cartItems = React.useMemo(() => cart.getCart(), [cart]);
	const [selectedProduct, setSelectedProduct] = useState<Array<number>>([]);

	const rebindFunction = (plan: ProductService.PlanogramItem, e: number) => {
		handleChangeQuantity(plan, e);
	};

	const handleChangeQuantity = async (plan: ProductService.PlanogramItem, qnty: number) => {
		const cartItem = cartItems.find((item) => item.id === plan?.id);
		const actualQnty = !cartItem ? 1 : qnty - cartItem.quantity;
		cart.updateCartItem(plan, actualQnty, false, false, false, store as any);
	};

	const {
		data: dataProductList,
	} = useProducts(Number(paramStore.storeId), category.id, category.name);

	const productsList = useMemo<ProductService.PlanogramItem[]>(() => {
		if (!dataProductList) {
			return []
		}

		return dataProductList?.pages.flatMap((page) => page.data) || [];
	}, [dataProductList]);

	const handleSelectedIds = (plan: ProductService.PlanogramItem) => {
		let data = [...selectedProduct];
		if (data.includes(plan.id)) {
			data = data.filter((item) => item != plan.id);
		} else {
			data.push(plan.id);
		}
		setSelectedProduct(data);
	};

	const verifySelectedProds = React.useCallback(async () => {
		setSelectedProduct((selected) => {
			return selected.filter((item) => cart.cartIds.includes(item));
		});
	}, [cart.cartIds]);

	useEffect(() => {
		verifySelectedProds();
	}, [verifySelectedProds]);

	const renderItem = ({ item: product, index }: { item: ProductService.PlanogramItem, index: number }) => {
		return (
			<Product
				key={index}
				onPress={() => navigation.navigate('Product', { product })}
				imgUrl={product?.product?.image_url}
				onQuantityChange={(e) => rebindFunction(product, e)}
				onQuantityOpen={() => handleSelectedIds(product)}
				price_list={product?.price_list_in_cents / 100}
				quantity={
					cartItems.find((item) => item.id === product.id)?.quantity || 0
				}
				title={product?.product.name}
				price_final={product?.price_final_in_cents / 100}
				quantityOpen={
					(cartItems.find((item) => item.id === product.id)?.quantity ||
						0) >= 1
				}
				style={{
					marginRight: 14,
				}}
				maxQuantity={product.stock_amount}
				testID={product.id.toString()}
			/>
		)
	}

	const keyExtractor = useCallback((item: ProductService.PlanogramItem, i: number) => `${i}-${item.id}`, []);

	return (
		<BaseClass
			title={productsList?.length > 0 ?  category.name: ""}
			isLast={false}
			seeMoreAction={() => navigation.navigate('Category', {
				name: category.name,
				categoryId: category.id
			})}
			productLength={productsList?.length > 0}
		>
			<FlatList
				contentContainerStyle={{
					paddingRight: 40,
				}}
				data={productsList}
				horizontal
				keyExtractor={keyExtractor}
				renderItem={renderItem}
				showsHorizontalScrollIndicator={false}
			/>
		</BaseClass>
	);
};
