import { useQuery } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';

export default function useOrders(
  storeId: number | null | undefined, 
  pwaId: string | undefined
) {
  const queryFn = async () => {
    const { data: orders } = await api.order.getSalesByTotem({
      store: storeId,
      pwaId,
    });
    return orders;
  };

  return useQuery({
    enabled: !!storeId && !!pwaId,
    queryFn,
    queryKey: queryKeys.ORDERS(storeId, pwaId),
  });
}
