import { NativeStackNavigationOptions, createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { StoreStackParamList } from '../routes.types';

import Home from '../../pages/home';
import SearchProducts from '../../pages/searchProducts';
import Category from '../../pages/category';
import Ad from '../../pages/Ad';

export const Stack = createNativeStackNavigator<StoreStackParamList>();

export default function StoreStack() {
  const options : NativeStackNavigationOptions = {
    headerShown: false,
  };

  return (
    <Stack.Navigator initialRouteName='Store' screenOptions={options}>
      <Stack.Screen component={Home} name="Store" />
      <Stack.Screen component={SearchProducts} name="SearchProducts" />
      <Stack.Screen component={Category} name="Category" />
      <Stack.Screen component={Ad} name="Advert" />
    </Stack.Navigator>
  );
}
