import { Orders } from '@inhouse-market/sdk';

export const getInvoiceStatus = (status: Orders.InvoiceStatus) => {
  if (status === 'pending') return 'Pendente';
  if (status === 'paid') return 'Paga';
  if (status === 'canceled') return 'Cancelada';
  if (status === 'in_analysis') return 'Em análise';
  if (status === 'draft') return 'Rascunho';
  if (status === 'partially_paid') return 'Parcialmente paga';
  if (status === 'refunded') return 'Reembolsada';
  if (status === 'expired') return 'Expirada';
  if (status === 'in_protest') return 'Em protesto';
  if (status === 'chargeback') return 'Contestada';
};
