import { useNavigation } from "@react-navigation/native";
import React from "react";
import { Linking } from "react-native";
import { Header } from "../../components/Header";
import {
	Container,
	ContainerImageStore,
	ContainerRow,
	ContainerRowDownload,
	ContainerStores,
	Content,
	IconContainer,
	ImageContainer,
	ImageMyBuys,
	PixValueContainer,
	PressableImage,
	TextButton,
	TextDownload,
	Value,
	Version,
	VersionText,
} from "./styles";
import pkg from "../../../app.json";
import HeaderNavigation from "../../components/HeaderNavigation";
import date from "../../utils/date";

async function redirectToSupport() {
	try {
		Linking.openURL(
			"https://api.whatsapp.com/send?phone=+558521806454&text=Olá preciso de ajuda."
		);
	} catch (error) {
		console.log(error);
	}
}

async function redirectToApple() {
	try {
		Linking.openURL(
			"https://apps.apple.com/us/app/shoppbud/id1622656578?ppid=042dd924-34cd-4f57-b314-c7425b935eaf"
		);
	} catch (error) {
		console.log(error);
	}
}

async function redirectToGoogle() {
	try {
		Linking.openURL(
			"https://play.google.com/store/apps/details?id=br.com.shoppbud"
		);
	} catch (error) {
		console.log(error);
	}
}

const CartEmpty = require("../../assets/cart_clean.svg");
const headset = require("../../../assets/headset.svg");
const playstore = require("../../../assets/playstore.svg");
const appstore = require("../../../assets/appstore.svg");

const Others: React.FC = () => {
	const navigation = useNavigation();

	return (
		<>
			<Container>
					<HeaderNavigation title="Outros" enableButtonLeft={false} enableButtonRight={false} />
					<ContainerRow onPress={() => navigation.navigate("MyBuys")} first>
						<IconContainer>
							<ImageMyBuys
								resizeMode="contain"
								source={CartEmpty}
							/>
						</IconContainer>
						<TextButton>Minhas compras</TextButton>
					</ContainerRow>
					<ContainerRow onPress={redirectToSupport}>
						<ImageContainer
							resizeMode="contain"
							source={headset}
						/>
						<TextButton>Falar com o suporte</TextButton>
					</ContainerRow>
					<ContainerRowDownload>
						<TextDownload>Baixar o app ShoppBud:</TextDownload>
					</ContainerRowDownload>
					<ContainerStores>
						<PressableImage onPress={redirectToApple}>
							<ContainerImageStore
								resizeMode="cover"
								source={appstore}
							/>
						</PressableImage>
						<PressableImage onPress={redirectToGoogle}>
							<ContainerImageStore
								resizeMode="cover"
								source={playstore}
							/>
						</PressableImage>
					</ContainerStores>
				<Version>
					<VersionText>
						PWA: {pkg?.expo?.version} - {date(pkg?.expo?.releasedAt).format('DD/MM/YY')}
					</VersionText>
				</Version>
			</Container>
		</>
	);
};

export default Others;
