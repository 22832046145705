import React from "react";
import { Image } from "react-native";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createNativeStackNavigator } from '@react-navigation/native-stack'


import { theme } from "../global/theme";
import { useCart } from "../hooks/use-cart";
import { RootStackParamList, RootTabsParamList } from "./routes.types";
import StoreStack from "./stacks/store";
import CartStack from "./stacks/cart";
import PaymentStack from "./stacks/payment";
import OthersStack from "./stacks/others";
import storeIcon from "../../assets/storeIcon.png";
import cartIcon from "../../assets/cartIcon.png";
import Product from "../pages/Product";
import PurchaseDetails2 from "../pages/purchaseDetails";
import { CartContextProps } from "../contexts";

const paymentIcon = require("../../assets/pay.svg");
const menuIcon = require("../assets/menu.svg");

const RootTabs = createBottomTabNavigator<RootTabsParamList>();
const RootStack = createNativeStackNavigator<RootStackParamList>();

export default function Routes() {
	return (
		<RootStack.Navigator
			screenOptions={({ route }) => ({
				headerShown: false,	
				tabBarLabelPosition: "below-icon",
				tabBarLabelStyle: {
					fontSize: 13,
					fontFamily: theme.fonts.bold,
					color: theme.colors.tertiary,
					marginBottom: 15,
				},
				tabBarIconStyle: {
					display: "flex",
					justifyContent: "center",
					height: "100%",
					marginTop: 10,
				},
				tabBarActiveTintColor: theme.colors.tertiary,
				tabBarInactiveTintColor: "#999DA6",
				tabBarStyle: { display: 'none' },
			})}
			initialRouteName="Tabs"
		>
      <RootStack.Screen component={Product} name="Product" />
      <RootStack.Screen component={PurchaseDetails2} name="PurchaseDetails" />
			<RootStack.Screen component={TabsRoutes} name="Tabs" />
		</RootStack.Navigator>
	);
}

function TabsRoutes() {
	const cart: CartContextProps = useCart();

  return (
    <RootTabs.Navigator
			screenOptions={({ route }) => ({
				headerShown: false,
				tabBarLabelPosition: "below-icon",
				tabBarLabelStyle: {
					fontSize: 13,
					fontFamily: theme.fonts.bold,
					color: theme.colors.tertiary,
					marginBottom: 15,
				},
				tabBarIconStyle: {
					display: "flex",
					justifyContent: "center",
					height: "100%",
					marginTop: 10,
				},
				tabBarActiveTintColor: theme.colors.tertiary,
				tabBarInactiveTintColor: "#999DA6",
				tabBarStyle: { height: 80, zIndex: -1 },
			})}
		>
			<RootTabs.Screen 
				options={{
					tabBarIcon: ({ color, size }) => (
						<Image
							style={{
								width: size,
								height: size,
								tintColor: color,
							}}
							source={storeIcon}
						/>
					),
					tabBarLabel: "Loja",
					unmountOnBlur: true,
				}}
				component={StoreStack} 
				name="StoreTab"
			/>
			<RootTabs.Screen 
				options={{
					tabBarIcon: ({ color, size }) => (
						<Image
							style={{
								width: size,
								height: size,
								tintColor: color,
							}}
							source={cartIcon}
						/>
					),
					tabBarBadge: cart?.itemsTotal > 0 
						? cart?.itemsTotal as number 
						: undefined,
					tabBarLabel: "Carrinho",
					unmountOnBlur: true,
					tabBarStyle: { 
						display: cart.itemsTotal > 0 ? 'none' : 'flex', 
						minHeight: 80,
					},
				}}
				component={CartStack} 
				name="CartTab"
			/>
			<RootTabs.Screen 
				options={{
					tabBarIcon: ({ color, size }) => (
							<Image
								style={{
									width: size,
									height: size,
									tintColor: color,
								}}
								resizeMode="stretch"
								source={paymentIcon}
							/>
					),
					tabBarLabel: "Pagar",
					unmountOnBlur: true,
				}}
				component={PaymentStack} 
				name="PaymentTab"
			/>
			<RootTabs.Screen 
				options={{
					tabBarIcon: ({ color, size }) => (
						<Image
							style={{
								width: size,
								height: size,
								tintColor: color,
							}}
							resizeMode="stretch"
							source={menuIcon}
						/>
					),
					tabBarLabel: "Outros",
					unmountOnBlur: true,
				}}
				component={OthersStack} 
				name="OthersTab"
			/>
    </RootTabs.Navigator>
  );
}
