import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Advert, Category as CategoryService, Lock as LockService, Product } from '@inhouse-market/sdk';
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useIsFocused } from "@react-navigation/core";
import { useNavigation } from "@react-navigation/native";
import { v4 as uuidv4 } from "uuid";
import { Header } from "../../components/Header";
import { ModalInHouse } from "../../components/ModalInHouse/modalInHouse";
import { ProductsClass } from "../../components/ProductsClass";
import Carrossel from "../../components/Carrossel/Carousel";
import SearchButton from "../../components/SearchButton";
import { useStore } from "../../hooks/use-store";
import { Planogram } from "../../models/planogram";
import useStores from '../../store/store/useStore';
import api from "../../services/api";
import {
	CategoriesContainer,
	CategoriesContainerContent,
	CategoriesContent,
	Container,
	Content,
	EmptyMessage,
	EmptyStore,
	TextCategories,
	ContainerAreaSpaceButton,
	LockIcon,
	ContainerAreaSpaceButtonLock,
	UpContainer,
} from "./styles";
import { Button } from "../../components/Button";
import ModalStatusLock from "../../components/ModalStatusLock";
import ScanQRCode from "../../components/ScanQRCode";
import BarcodeButton from "../../components/BarcodeButton";
import ScanBarcode from "../../components/ScanBarcode";
import ModalNotBarcode from "../../components/ModalNotBarcode";
import axios from "axios";

import useCategories from "../../store/category/useGetCategory";
import useAdverts from "../../store/advert/useAdverts";
import { FlatList } from "react-native";
import useLocks from "../../store/lock/useLocks";
import { ButtonContainer, TextLoadingCategory } from "../category/styles";

interface Lock {
	id: string;
	is_for_legal_age: boolean;
	qrcode: string;
	status: number;
}

const Home: React.FC = () => {
	const [visible, setVisible] = useState(false);
	const [visibleHeader, setVisibleHeader] = useState(false);
	const [modalProductNotFound, setModalProductNotFound] = useState(false);
	const [camOpened, setCamOpened] = useState(false);
	const [barcodeOpened, setBarcodeOpened] = useState(false);
	const [isOpenModal, setIsOpenModal] = useState(false)
	const [modalInfo, setModalInfo] = useState<{
		error: 'ERROR' | 'SUCCESS' | 'ALERT';
		title: string
		description: string
		onTimeClose: boolean
		action: () => void
	}>({
		title: '',
		description: '',
		error: 'SUCCESS',
		onTimeClose: false,
		action: () => { }
	})
	const flatListRef = useRef(null);

	const isFocused = useIsFocused();
	const navigation = useNavigation();
	const paramStore = useStore();
	//
	const {
		data: adverts,
	} = useAdverts({ showIn: 'INSIDESTORE', storeId: Number(paramStore.storeId) });
	//
	const {
		data: store,
	} = useStores(Number(paramStore.storeId));
	//
	const {
		data: locks,
	} = useLocks(Number(paramStore.storeId));
	//
	const {
		data: category,
		fetchNextPage: fetchNextPageCategory,
		hasNextPage: hasNextPageCategory,
		isLoading: isLoadingCategory,
		isInitialLoading: isInitialLoadingCategory,
		isFetching: isFetchingCategory
	} = useCategories(Number(paramStore.storeId));

	const categoryData = useMemo<CategoryService.CategoryItem[]>(() => {
		if (!category) {
			return []
		}

		return category?.pages.flatMap((page) => page.data) || [];
	}, [category]);

	const hideModalAndShowHeader = () => {
		setVisible(false);
		setVisibleHeader(true);
	};

	const hideModal = () => {
		setVisible(false);
	};

	const openListApartment = () => {
		AsyncStorage.setItem("@openHeader", "true");
		setVisibleHeader(true);
	};

	const haveSomeLockToUnlock = React.useMemo(() => {
		return locks?.some((item) => item.status);
	}, [store, locks]);

	const handleOpenLock = async (lockId?: number | string) => {
		try {
			await api.get<Planogram>(`/store/${store?.id}/lock${lockId ? `/${lockId}` : ''}`);
			setCamOpened(false)
			setModalInfo({
				...modalInfo,
				title: 'Comando de abertura de trava enviado com sucesso!',
				description: 'Verifique se a trava foi aberta',
				error: 'SUCCESS',
				onTimeClose: true
			})
		} catch (err) {
			setCamOpened(false)
			setModalInfo({
				...modalInfo,
				title: 'Comando de abertura de trava falhou!',
				description: 'Tente novamente ou contate o suporte',
				error: 'ERROR',
				onTimeClose: false
			})
		} finally {
			setIsOpenModal(true)
		}
	}

	const failedToOpenQrCodeScan = () => {
		setCamOpened(false)
		setModalInfo({
			...modalInfo,
			title: 'Comando de abertura de trava falhou!',
			description: 'Tente novamente ou contate o suporte',
			error: 'ERROR',
			onTimeClose: false
		})
		setIsOpenModal(true)
	}

	const handleQRCodeScan = (result: { data: string }) => {
		const lock = locks?.filter(
			(item) => item.qrcode == result.data && item.status,
		);
		if (lock && lock?.length <= 0) {
			setCamOpened(false)
			setModalInfo({
				...modalInfo,
				title: 'Verifique se o QR Code está correto ou contate o suporte',
				description: 'QR Code inválido',
				error: 'ALERT',
				onTimeClose: false
			})
			setIsOpenModal(true)
			return
		}

		handleOpenLock(lock && lock.length > 0 ? lock[0]?.id : '');
		setCamOpened(false)
	}

	function shouldScanQRCode(locks: LockService.LockItem[], store: any) {
		return (
			locks.some((item: any) => Boolean(item.status)) &&
			Boolean(store.is_qr_code_required)
		);
	}


	const onPressUnlock = () => {
		if (shouldScanQRCode(locks ? locks : [], store)) return setCamOpened(true);
		handleOpenLock();
	}

	useEffect(() => {
		AsyncStorage.setItem("@openHeader", "false");
	}, []);

	useEffect(() => {
		if (!isFocused) return;
		if (paramStore.storeId === '') setVisible(true);
	}, [isFocused]);

	const verifyAndCreateUUID = React.useCallback(async () => {
		const item = await AsyncStorage.getItem("id_pwa");
		if (item) return;
		AsyncStorage.setItem("id_pwa", uuidv4());
	}, []);


	useEffect(() => {
		verifyAndCreateUUID();
	}, []);

	useEffect(() => {
		if (camOpened || barcodeOpened) {
			navigation.setOptions({
				tabBarStyle: { display: "none" },
			});
			return
		}
		navigation.setOptions({
			tabBarStyle: { height: '80px', display: 'flex' },
		});
	}, [camOpened, barcodeOpened]);

	const handleReadBarCode = async (code: string) => {
		try {
			const response = await api.get<Product.PlanogramItem>(
				`/store/${paramStore.storeId}/planogram/barcode/${code}`,
			);
			if (!response.data) {
				setModalProductNotFound(true)
				return;
			}

			navigation.navigate('Product', { product: response.data })
		} catch (error) {
			if (axios.isAxiosError(error)) setModalProductNotFound(true);
		}
	};

	const handleIntersection = (entries: IntersectionObserverEntry[]) => {
		const entry = entries[0];
		if (entry.isIntersecting && hasNextPageCategory) {
			fetchNextPageCategory();
		}
	};

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, { threshold: 0.1 });

		if (flatListRef.current) {
			observer.observe(flatListRef.current);
		}

		return () => {
			if (flatListRef.current) {
				observer.unobserve(flatListRef.current);
			}
		};
	}, [flatListRef.current]);

	const keyExtractor = useCallback((item: CategoryService.CategoryItem, i: number) => `${i}-${item.id}`, []);

	return (
		<>
			{camOpened && <ScanQRCode setCamOpened={setCamOpened} getResult={handleQRCodeScan} onError={failedToOpenQrCodeScan} />}
			{barcodeOpened && <ScanBarcode barcodeOpened={barcodeOpened} setBarcodeOpened={setBarcodeOpened} onResult={handleReadBarCode} onError={() => setModalProductNotFound(true)} />}

			{!camOpened && !barcodeOpened &&
				<>
					<Header visibleHeader={visibleHeader} hideModalAndShowHeader={hideModalAndShowHeader} />
					<Container>
						<Content>
							{categoryData?.length <= 0 && !isLoadingCategory ? (
								<EmptyStore>
									<EmptyMessage>
										Não há produtos cadastrados nessa loja
									</EmptyMessage>
								</EmptyStore>
							) : (
								<CategoriesContainer
									showsHorizontalScrollIndicator={false}
								>
									<CategoriesContainerContent
										horizontal
										showsHorizontalScrollIndicator={false}
									>
										{categoryData.map((item: { id: number, name: string }) => {
											return (
												<CategoriesContent
													selected={false}
													key={item.id}
													onPress={() => navigation.navigate('Category', {
														name: item.name,
														categoryId: item.id
													})}
												>
													<TextCategories selected={false}>
														{item.name}
													</TextCategories>
												</CategoriesContent>
											);
										})}
									</CategoriesContainerContent>
									{adverts && adverts?.length > 0 && (
										<Carrossel
											item={adverts || []}
										/>
									)}
									<FlatList
										data={categoryData}
										showsVerticalScrollIndicator={false}
										keyExtractor={keyExtractor}
										renderItem={({ item }) => (
											<ProductsClass category={item} store={store as any} />
										)}
										ListFooterComponent={() => (
											<ButtonContainer ref={flatListRef}>
												{isInitialLoadingCategory && (
													<TextLoadingCategory>Carregando Produtos...</TextLoadingCategory>
												)}
											</ButtonContainer>
										)}
									/>
									{isFetchingCategory && !isInitialLoadingCategory && (
										<ButtonContainer ref={flatListRef}>
											<TextLoadingCategory>
												Carregando Produtos...
											</TextLoadingCategory>
										</ButtonContainer>
									)}
								</CategoriesContainer>
							)}
						</Content>
						{visible && (
							<ModalInHouse
								params={paramStore}
								hideModal={hideModal}
								openListApartment={openListApartment}
								hideModalAndShowHeader={hideModalAndShowHeader}
								store={store as any}
							/>
						)}

						<UpContainer>
							<BarcodeButton onPress={() => setBarcodeOpened(!barcodeOpened)} />
						</UpContainer>

						<ContainerAreaSpaceButton>
							<SearchButton />
						</ContainerAreaSpaceButton>

						{haveSomeLockToUnlock &&
							<ContainerAreaSpaceButtonLock>
								<Button type="primary" onPress={onPressUnlock}>
									<LockIcon source={require('../../assets/lock.svg')} />
									Destravar porta
								</Button>
							</ContainerAreaSpaceButtonLock>
						}

					</Container>
				</>
			}

			{isOpenModal &&
				<ModalStatusLock
					isOpen={isOpenModal}
					setIsOpen={setIsOpenModal}
					type={modalInfo.error}
					title={modalInfo.title}
					description={modalInfo.description}
					action={modalInfo.action}
					onTimeClose={modalInfo.onTimeClose} />
			}

			<ModalNotBarcode
				isOpen={modalProductNotFound}
				setIsOpen={setModalProductNotFound}
				title="Código de barras não encontrado!"
				description="Você pode pesquisar seu produto pelo nome"
				action={() => navigation.navigate("SearchProducts")}
			/>
		</>
	);
};

export default Home;
