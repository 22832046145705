import { NativeStackNavigationOptions, createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { CartStackParamList } from '../routes.types';

import Cart from '../../pages/cart';

export const Stack = createNativeStackNavigator<CartStackParamList>();

export default function CartStack() {
  const options : NativeStackNavigationOptions = {
    headerShown: false,
  };

  return (
    <Stack.Navigator initialRouteName='Cart' screenOptions={options}>
      <Stack.Screen component={Cart} name="Cart" />
    </Stack.Navigator>
  );
}
