import { NativeStackNavigationOptions, createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { PaymentStackParamList } from '../routes.types';

import Payment from '../../pages/payment';
import Result from '../../pages/result';
import Erro from '../../pages/erro';


export const Stack = createNativeStackNavigator<PaymentStackParamList>();

export default function PaymentStack() {
  const options : NativeStackNavigationOptions = {
    headerShown: false,
  };

  return (
    <Stack.Navigator initialRouteName='Payment' screenOptions={options}>
      <Stack.Screen component={Payment} name="Payment" />
      <Stack.Screen component={Result} name="Result" />
      <Stack.Screen component={Erro} name="Error" />
    </Stack.Navigator>
  );
}
