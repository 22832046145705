import { QueryClient } from '@tanstack/react-query';

const queryClient = new QueryClient();

export const queryKeys = {
  STORE_DETAILS: (id: null | number) => ['STORE_DETAILS', id],
  STORES: (latitude: number, longitude: number, name?: string) => ['STORES', latitude, longitude, name],
  LOCKS: (storeId: number) => ['LOCKS', storeId],
  PRODUCTS: (storeId: number, categoryId?: number, name?: string) => ['PRODUCTS', storeId, categoryId, name],
  // ADVERT
  ADVERTS: (showIn: "INSIDESTORE" | "OUTSTORE" | "SLEEPSCREEN", storeId: string | number | null | undefined) => ['ADVERTS', showIn, storeId],
  ADVERTS_DETAILS: ['ADVERT_DETAIL'],
  ADVERTS_GLOBAL: ['ADVERTS_GLOBAL'],
  //CATEGORY
  CATEGORY: (storeId: number) => ['CATEGORY', storeId],
  //PAYMENT
  PURCHASE: ['PURCHASE'],
  //ORDER
  ORDER: (orderId: number) => ['ORDER', orderId],
  ORDERS: (storeId: number | null | undefined, pwaId: string | undefined) => ['ORDERS', storeId, pwaId],
}

export default queryClient;