import React, { useState } from "react";
import * as Linking from "expo-linking";
import { useNavigation } from "@react-navigation/native";
import { Header } from "../../components/Header";

import {
	Container,
	Content,
	AdWrapper,
	Image,
	Name,
	Description,
	Link,
	LinkText,
	AdWrapperImage,
	BackContainer,
	BackText,
} from "./styles";
import { AdvertScreenProps } from "../../routes/routes.types";

const Ad: React.FC<AdvertScreenProps> = ({ route }) => {
	const { canGoBack, goBack } = useNavigation();

	const [ad, setAd] = useState(route?.params?.ads);

	React.useEffect(() => {
    const setStateAd = () => {
      setAd(route?.params?.ads)
    }

    if (route?.params?.ads) {
      setStateAd()
    }

  }, [route])

	const onBackPress = React.useCallback(() => {
		if (canGoBack()) {
			goBack();
		}
	}, [goBack]);

	return (
		<Container>
			<Header title="Anúncio" hideModalAndShowHeader={()=> {}} />
			<BackContainer
				onPress={onBackPress}
				testID="btn_back"
			>
				<BackText>Voltar</BackText>
			</BackContainer>
			<Content showsVerticalScrollIndicator={false}>
				<AdWrapper>
					<AdWrapperImage>
						<Image
							source={{ uri: ad?.image }}
							testID="ad_image"
						/>
					</AdWrapperImage>
					<Name testID="ad_name">{ad?.name}</Name>
				</AdWrapper>
				<Description testID="ad_description">
					{ad?.description}
				</Description>
			</Content>

			{ad?.link && (
				<Link onPress={() => Linking.openURL(ad?.link)}>
					<LinkText testID="ad_link">Visite nosso site</LinkText>
				</Link>
			)}
		</Container>
	);
};

export default Ad;