import { Feather } from "@expo/vector-icons";
import {
	useIsFocused,
	useNavigation,
} from "@react-navigation/native";
import React, { useEffect, useRef, useState } from "react";
import {
	Image,
	RefreshControl,
	TouchableOpacity,
} from "react-native";
import QRCode from "react-native-qrcode-svg";
import ViewShot from "react-native-view-shot";
import { useScreenshot } from "use-react-screenshot";

import { Orders as OrdersType } from "../../services/apiSDK";
import { useStore as useStoreHook } from "../../hooks/use-store";
import { NumberToCurrency } from "../../utils/numberToCurrency";
import { PurchaseItem } from "./components/PurchaseItem";
import LoadingPage from "../../components/LoadingPage";
import { formatDate } from "../../utils/formatDate";
import { getInvoiceStatus } from "../../utils/iugu";
import { lockStores } from "../../utils/idsLocksStores";
import useOrder from "../../store/order/useOrder";
import useStore from "../../store/store/useStore";
import { PurchaseDetailScreenProps } from "../../routes/routes.types";

import {
	ConfirmButton,
	Container,
	ContainerHelp,
	ContainerQRCode,
	ContentButtonProducts,
	DisabledView,
	Help,
	ItemsScroll,
	LineContainer,
	LineText,
	PaymentDetailsContainer,
	PaymentDetailsTitle,
	PaymentLineContainer,
	PaymentTitle,
	ShareContainer,
	StoreContainer,
	StoreDetailsContainer,
	StoreImage,
	StoreLocation,
	StoreName,
	TextConfirmButton,
	TextDetails,
	Title,
	TotalContainer,
	TotalValue,
	ProofHeader,
	ProofHeaderNavigation,
	ProofPaymentDateArea,
	ProofPaymentDate,
	ProofPaymentStatus,
	DetailsOrder,
	LineArea,
	ShareProof,
	IconProof
} from "./styles";

const pixImage = require("../../assets/pix.svg");

const PurchaseDetails2: React.FC<PurchaseDetailScreenProps> = ({ route }) => {
	const [salesId, setSalesId] = useState<number | null>(null);
	
	const focused = useIsFocused();
  const navigation = useNavigation();
	const paramStore = useStoreHook();
	const viewShot = useRef<ViewShot | null>(null);
	const ref = useRef<HTMLDivElement | null>(null);
	const [image, takeScreenshot] = useScreenshot();
	
	const getImage = async () => await takeScreenshot(ref.current);

	useEffect(() => {
		if (route?.params) {
			setSalesId(Number(route.params.purchaseId));
		}
	}, [route]);

	useEffect(() => {
		navigation.setOptions({tabBarStyle: {display: 'none'}});
	}, [focused]);

	const {
		data: store,
		isLoading: loadingStore,
		isError: errorStore,
	} = useStore(Number(paramStore.storeId));

  const {
    data: order,
    isLoading: loadingOrder,
		refetch: refetchOrder,
		error: errorOrder,
  } = useOrder(salesId);

	useEffect(() => {
		if (store) {
			if (!store.active_pwa) {
				throw new Error("Store not found");
			}

			if (lockStores.includes(store.id)) {
				throw new Error("Store not found");
			}
		}
	}, [store])

	useEffect(() => {
		if (errorStore) {
			throw new Error("Store not found");
		}
	}, [errorStore])

	useEffect(() => {
		if (errorOrder) {
			throw new Error("Order not found");
		}
	}, [errorOrder])

	const getFormattedDate = (rawDateHour: string) => {
		const date = formatDate(new Date(rawDateHour));
		const hour = rawDateHour.match(/\d\d:\d\d:\d\d/);
		return date + " - " + hour?.[0];
	};

	const getDiscountInCents = () => {
		if (!order) return 0;

		const listPriceTotal = order?.saleItems.reduce((total: number, item: OrdersType.SaleItems) => {
			return total + item.listPrice * item.quantity;
		}, 0);

		return order.total < listPriceTotal
			? listPriceTotal - order.total
			: 0;
	};

	let generatePdf = async () => {
		try {
			let img = await getImage();
			var a = document.createElement("a"); //Create <a>
			a.href = img; //Image Base64 Goes here
			a.download = "comprovante.png"; //File name Here
			a.click();
		} catch (error) {
			console.log(error);
		}
	};

	if (loadingOrder || loadingStore) {
		return (
				<LoadingPage />
		);
	}

	return (
		<>
			<Container>
				<ItemsScroll
					refreshControl={
						<RefreshControl
							refreshing={loadingOrder}
							onRefresh={refetchOrder}
						/>
					}
				>
					<div ref={ref}>
						<ViewShot
							options={{ format: "jpg", quality: 0.9 }}
							style={{ flex: 1, backgroundColor: "#FFF" }}
							ref={viewShot}
						>
							{order && (
								<>
								  <ProofHeader>
										<TouchableOpacity onPress={
											() => navigation.reset({index: 0, routes: [{ name: "Tabs"}]})
										}>
											<ProofHeaderNavigation>Voltar</ProofHeaderNavigation>
										</TouchableOpacity>
									  <Title>Comprovante de compra</Title>
										<TouchableOpacity onPress={() => navigation.navigate("Support")}>
									   <ProofHeaderNavigation>Ajuda</ProofHeaderNavigation>
										</TouchableOpacity>
									</ProofHeader>
									<ProofPaymentDateArea>
										<ProofPaymentDate>
											{getFormattedDate(
												order?.createdAt
											)}
										</ProofPaymentDate>
										<ProofPaymentStatus
										>
											{getInvoiceStatus(order?.status)}
										</ProofPaymentStatus>
									</ProofPaymentDateArea>

									<StoreContainer>
										<StoreImage
											source={{ uri: store?.logo }}
											testID="image"
										/>
										<StoreDetailsContainer>
											<StoreName 
											 testID="name"
											 numberOfLines={1}
											 ellipsizeMode="tail"
											>
												{store?.name}
											</StoreName>
											<StoreLocation testID="address">
												{store?.address.street}
											</StoreLocation>
										</StoreDetailsContainer>
									</StoreContainer>


									<DisabledView
										isCanceled={
											order?.status === "canceled"
										}
									>
										<TotalContainer>
											<TotalValue testID="total_label">
												Total (
												{order?.saleItems.length} un)
											</TotalValue>
											<TotalValue testID="total_price">
												{NumberToCurrency(
													order?.total / 100
												)}
											</TotalValue>
										</TotalContainer>
									</DisabledView>

									<DisabledView
										isCanceled={
											order?.status === "canceled"
										}
									>
										{order?.saleItems.map(
											(item, index) => {
												return (
													<PurchaseItem
														key={index}
														item={item}
														isLast={order?.saleItems.length - 1 === index}
												/>
												)
											}
										)}
									</DisabledView>
									<LineArea>
									  <LineContainer first>
											<LineText>
												Subtotal
											</LineText>
											<LineText>
												{NumberToCurrency(
													order?.total / 100
												)}
											</LineText>
										</LineContainer>
										<LineContainer>
											<LineText>
												Desconto
											</LineText>
											<LineText>
												{NumberToCurrency(
													getDiscountInCents() / 100
												)}
											</LineText>
										</LineContainer>
										<LineContainer isLast>
											<LineText >
												Total
											</LineText>
											<LineText>
												{NumberToCurrency(
													order?.total / 100
												)}
											</LineText>
										</LineContainer>
									</LineArea>
										<DetailsOrder>
											<TotalValue testID="total_label">
													Total (
													{order?.saleItems.length} un)
												</TotalValue>
												<TotalValue testID="total_price">
													{NumberToCurrency(
														order?.total / 100
													)}
												</TotalValue>
										</DetailsOrder>
									<PaymentTitle>Pagamento</PaymentTitle>
									<PaymentLineContainer testID="payment_cc">
										<Image
											alt="pix"
											source={{ uri: pixImage}}
										/>
										<PaymentDetailsContainer>
											<PaymentDetailsTitle>
												PIX
											</PaymentDetailsTitle>
										</PaymentDetailsContainer>
									</PaymentLineContainer>
									<ContainerQRCode>
										<QRCode
											size={200}
											value={order?.id.toString()}
										/>
										<TextDetails>
											{`ID da Transação\nN° ${order?.id}`}
										</TextDetails>
										<ContainerHelp
											onPress={() => navigation.navigate("Support")}
										>
											<Feather
												name="alert-circle"
												size={24}
												color="#FF7700"
											/>
											<Help>Ajuda</Help>
										</ContainerHelp>
									</ContainerQRCode>
								</>
							)}
						</ViewShot>
					</div>
				</ItemsScroll>
				<ContentButtonProducts>
					<ShareProof onPress={() => generatePdf()}>
						<IconProof source={require('../../../assets/share.svg')} />
					</ShareProof>
					<ConfirmButton onPress={
						() => navigation.reset({index: 0, routes: [{ name: "Tabs"}]})
					}>
						<TextConfirmButton>
							Voltar ao início
						</TextConfirmButton>
					</ConfirmButton>
				</ContentButtonProducts>
			</Container>
		</>
	);
};

export default PurchaseDetails2;
