import styled from "styled-components/native";
import { theme } from "../../global/theme";

export const Container = styled.View`
	flex: 1;
	background-color: ${() => theme.colors.white};
	padding: 5px 20px 0;
	position: relative;
`;

export const OrderContainer = styled.View``;

export const Orders = styled.ScrollView`
	flex: 1;
`;

export const Store = styled.View`
	flex-direction: column;
	justify-content: space-between;
	padding: 12px 0px 5px 0px;
	border: 1px solid #ededed;
	margin-top: 5px;
	margin-bottom: 5px;
	border-top-right-radius: 10px;
	border-top-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
`;

export const StoreContent = styled.TouchableOpacity`
	flex-direction: row;
	padding: 10px;
`;

export const StoreImage = styled.Image`
	width: 68px;
	height: 68px;
	border-width: 1px;
	border-color: #ededed;
	border-radius: 34px;
	background-color: #ededed;
`;

export const BasicInfo = styled.View`
	margin-left: 10px;
	padding: 5px;
	flex: 1;
	justify-content: center;
`;

export const StoreTitle = styled.Text`
	font-size: 14px;
	font-family: ${() => theme.fonts.bold};
	color: ${() => theme.colors.tertiary};
	margin-right: 12px;
`;

export const ArrowContainer = styled.View`
	margin-right: 4px;
	margin-top: 4px;
	margin-left: -10px;
`;

export const TitleContainer = styled.View`
	align-items: flex-start;
	flex-direction: row;
	justify-content: space-between;
`;

export const PriceContainer = styled.View`
	flex: 1;
	min-width: 10px;
	align-items: flex-end;
	margin-bottom: 10px;
`;

export const TextPrice = styled.Text`
	font-size: 14px;
	font-family: ${() => theme.fonts.bold};
	color: ${() => theme.colors.tertiary};
	margin-bottom: -10px;
`;

export const PaymentMethod = styled.Text`
	font-size: 14px;
	font-family: ${() => theme.fonts.regular};
	color: ${() => theme.colors.gray};
`;

export const CenterContent = styled.View`
	flex: 1;
	align-items: center;
	justify-content: center;
	margin: 0 30px 50px;
`;

export const NoStoreMessage = styled.Text`
	color: ${() => theme.colors.gray};
	font-size: 14px;
	text-align: center;
`;

export const HelpText = styled.Text`
	color: ${() => theme.colors.primary};
	font-family: ${() => theme.fonts.regular};
	font-size: 14px;
	text-align: center;
	margin-left: 8px;
`;

export const HelpContainer = styled.TouchableOpacity`
	flex-direction: row;
	justify-content: center;
	align-items: center;
	border-top-width: 1px;
	border-top-color: #ededed;
	padding: 10px;
`;

interface LineTextProps {
	color:
		| "tertiary"
		| "disabledButtonText"
		| "green"
		| "secondary"
		| "red"
		| "yellow";
	fontWeight?: "bold" | "regular";
	isTotal?: boolean;
}

export const LineText = styled.Text<LineTextProps>`
	color: ${({ color }) =>
		color === "yellow" ? "#fdba14" : theme.colors[color]};
	font-family: ${({ fontWeight }) =>
		fontWeight ? theme.fonts[fontWeight] : theme.fonts.regular};
	font-size: ${(props) => (!!props.isTotal ? "18" : "13")}px;
`;

interface LineContainerProps {
	first?: boolean;
	marginTop?: number;
}

export const LineContainer = styled.View<LineContainerProps>`
	flex-direction: row;
	justify-content: space-between;
	align-items: flex-end;
	padding-left: 20px;
	padding-right: 20px;
	${(props) =>
		props.marginTop
			? `margin-top: ${props.marginTop}px`
			: !!props.first &&
			  `
  margin-top: 20px;
  `}
`;

export const ListMessage = styled.Text`
	font-size: 14px;
	text-align: center;
	font-family: ${() => theme.fonts.regular};
	color: ${() => theme.colors.gray};
	margin: 20px;
`;

export const ImageMyBuys = styled.Image`
	width: 32px;
	height: 32px;
`;
