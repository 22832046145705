import { useQuery } from '@tanstack/react-query';

import api from '../../services/apiSDK';
import { queryKeys } from '../queryClient';
import { TIME_TO_UPDATE_PURCHASE_STATUS } from '../../utils/contants';

export default function useOrder(orderId: number | null) {
  const queryFn = async () => {
    const order = await api.order.get(Number(orderId));

    if (order[0].status === "canceled") {
      throw new Error('Purchase canceled');
    }

    return order[0];
  };

  return useQuery({
    enabled: !!orderId,
    queryFn,
    queryKey: queryKeys.ORDER(Number(orderId)),
    refetchInterval: (data) => {
      return data?.status === "paid" ? false : TIME_TO_UPDATE_PURCHASE_STATUS;
    },
    refetchOnMount: true,
    refetchOnReconnect: true,
    refetchOnWindowFocus: true,
    retry: false,
    staleTime: Infinity,
  });
}
