import { NativeStackNavigationOptions, createNativeStackNavigator } from '@react-navigation/native-stack';
import React from 'react';

import { OthersStackParamList } from '../routes.types';

import Others from '../../pages/others';
import { MyBuys } from '../../pages/myBuys';
import Support from '../../pages/support';


export const Stack = createNativeStackNavigator<OthersStackParamList>();

export default function OthersStack() {
  const options : NativeStackNavigationOptions = {
    headerShown: false,
  };

  return (
    <Stack.Navigator initialRouteName='Others' screenOptions={options}>
      <Stack.Screen component={Others} name="Others" />
      <Stack.Screen component={MyBuys} name="MyBuys" />
      <Stack.Screen component={Support} name="Support" />
    </Stack.Navigator>
  );
}
