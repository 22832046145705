import { AntDesign } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import React, { useEffect } from "react";
import { ScrollView } from "react-native";
import {
	ChangeButton,
	ConfirmButton,
	ContainerContentErro,
	ContentErro,
	ErroText,
	TextChange,
	TextConfirmButton,
	BtnContainer,
	BtnArea,
	LogoArea,
	Logo,
	NoticeText,
	Container
} from "./styles";
import HeaderNavigation from "../../components/HeaderNavigation";
import { ResultScreenProps } from "../../routes/routes.types";

const Result: React.FC<ResultScreenProps> = ({ route }) => {
	const navigation = useNavigation();

	const success = React.useMemo(() => route?.params.success ?? false, [route]);

	const results = React.useMemo(() => {
		if (success) {
			return {
				icon: "checkcircle",
				iconColor: "#00BA00",
				title: "Obrigado, recebemos seu pagamento!",
				buttonText: "Ver comprovante",
			}
		} else {
			return {
				icon: "closecircle",
				iconColor: "#FA3E25",
				title: "Desculpe ocorreu um erro ao carregar a página\nTente novamente mais tarde.",
				buttonText: "VOLTAR AO CARRINHO",
			}
		}
	}, [success]);

	useEffect(() => {
		navigation.setOptions({tabBarStyle: {display: 'none'}});
	}, [])

	const handleNavigateToPurchaseDetails = () => {
		navigation.navigate("PurchaseDetails", { 
			purchaseId: route.params.purchaseId
		});
	}

	return (
		<Container>
		<HeaderNavigation title="Pagamento" enableButtonLeft enableButtonRight={false} />
		<ScrollView>
			<ContainerContentErro>
				<ContentErro>
					<AntDesign
						name={results?.icon as any}
						size={147}
						color={results?.iconColor}
					/>
					<ErroText>{results?.title}</ErroText>
					<NoticeText>
					  Quer pagar com cartão de crédito? <br /> Baixe o app SHOPPBUD na app store
					</NoticeText>
					<LogoArea>
				  	<Logo source={require('../../../assets/shoppbud.svg')} />
				  </LogoArea>
				</ContentErro>
			
			</ContainerContentErro>
		</ScrollView>
		<BtnContainer>
			<BtnArea>
				<ChangeButton onPress={() => navigation.navigate("StoreTab")}>
						<TextChange>
							Voltar ao início
						</TextChange>
				</ChangeButton>
			</BtnArea>
			<BtnArea>
					<ConfirmButton onPress={handleNavigateToPurchaseDetails}>
							<TextConfirmButton>
									Ver comprovante
								</TextConfirmButton>
					</ConfirmButton>
					</BtnArea>
		</BtnContainer>
		</Container>
	);
};

export default Result;
