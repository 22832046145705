import {	useNavigation } from "@react-navigation/native";
import React, { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { FlashList } from "@shopify/flash-list";

import LoadingPage from "../../components/LoadingPage";
import { Header } from "../../components/Header";
import { formatDate } from "../../utils/formatDate";
import { useStore } from "../../hooks/use-store";
import useOrders from "../../store/order/useOrders";
import { Orders as OrdersType } from "../../services/apiSDK";
import { getInvoiceStatus } from "../../utils/iugu";
import {
	BasicInfo,
	CenterContent,
	Container,
	LineContainer,
	LineText,
	NoStoreMessage,
	PaymentMethod,
	OrderContainer,
	Orders,
	Store,
	StoreContent,
	StoreImage,
	StoreTitle,
	TitleContainer,
} from "./styles";

export const MyBuys: React.FC = () => {
	const [pwaId, setPwaId] = useState<string | undefined>(undefined);

	const paramStore = useStore();
	const navigation = useNavigation();

	useEffect(() => {
		const fetchPwaId = async () => {
			const id = await AsyncStorage.getItem("id_pwa");
			setPwaId(id ?? undefined);
		};
		fetchPwaId();
	}, []);

	const {
		data: orders,
		isLoading: isLoadingOrders,
	} = useOrders(Number(paramStore.storeId), pwaId);

	const getFormattedDate = (rawDateHour: string) => {
		const date = formatDate(new Date(rawDateHour));
		const hour = rawDateHour.match(/\d\d:\d\d:\d\d/);
		return date + " - " + hour?.[0];
	};

	const getColorStatus = (status: OrdersType.InvoiceStatus) => {
		if (status === "canceled") return "red";
		if (status === "paid") return "green";
		return "yellow";
	};
	
	const renderItem = ({ item: order }: { item: OrdersType.ListOrdersDetails}) => {
		return (
			<OrderContainer key={order.id}>
			<Store>
				<LineContainer marginTop={10}>
					<LineText
						color="tertiary"
						testID={`date_${order.id}`}
					>
						{getFormattedDate(
							order.created_at
						)}
					</LineText>
					<LineText
						color={getColorStatus(
							order.status as OrdersType.InvoiceStatus
						)}
						fontWeight="bold"
						style={{ marginRight: 5 }}
						testID={`status_${order.id}`}
					>
						{getInvoiceStatus(
							order.status as OrdersType.InvoiceStatus
						) || order.status}
					</LineText>
				</LineContainer>
				<StoreContent
					onPress={() =>
						navigation.navigate(
							"PurchaseDetails",
							{
								purchaseId: String(order.id),
								isProofOrder: false,
								isPixOrder: false,
							}
						)
					}
					testID={`btn_details_${order.id}`}
				>
					<StoreImage
						source={{
							uri: order.store.logo,
						}}
						testID={`image_${order.id}`}
					/>

					<BasicInfo>
						<TitleContainer>
							<StoreTitle
								ellipsizeMode="tail"
								numberOfLines={1}
								testID={`name_${order.id}`}
							>
								{order.store.name}
							</StoreTitle>
						</TitleContainer>

						<PaymentMethod
							testID={`payment_${order.id}`}
						>
							{order.store.address.street}
						</PaymentMethod>
					</BasicInfo>
				</StoreContent>
			</Store>
		</OrderContainer>
		)
	}

	if (isLoadingOrders) {
		return <LoadingPage />;
	}


	return (
		<>
			<Header title="Minhas Compras" />
			<Container>
				{orders?.length === 0 ? (
					<CenterContent testID="no_content">
						<NoStoreMessage>Nenhuma compra</NoStoreMessage>
					</CenterContent>
				) : (
					<Orders
						contentContainerStyle={{ flex: 1 }}
						showsVerticalScrollIndicator={false}
					>
						<FlashList
							data={orders}
							keyExtractor={(item, index) => `${index}-${item.id}`}
							renderItem={renderItem}
							estimatedItemSize={10}
							showsVerticalScrollIndicator
						/>
					</Orders>
				)}
			</Container>
		</>
	);
};
